import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "context";
import styled from "@emotion/styled";
import { theme } from "assets/theme";
import { css } from "@emotion/css";
import axios from "axios";

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid ${theme.colors.accent.grey};
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.15s;
  width: 100%;
  height: 40px;
  background-color: ${theme.colors.primary["primary/03"]};
  color: ${theme.colors.text["text/main"]};
  font-size: ${theme.font["body-sm"].fontSize};
  font-weight: ${theme.font["body-sm"].fontWeight};
  font-family: ${theme.font["body-sm"].fontFamily};
  padding-right: 12px;
  padding-left: 12px;
  &::placeholder {
    color: ${theme.colors.text["text/75%"]};
    font-size: ${theme.font["body-sm"].fontSize};
  }
  &:hover,
  &:focus {
    border-color: ${theme.colors.accent["blue/main"]};
  }
`;

const Button = styled.button`
  all: unset;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  height: 47px;
  width: fit-content;
  padding-inline: 22px;
  border-radius: 8px;
  background-color: ${theme.colors.accent["purple/main"]};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const Section = styled.section`
  margin: auto;
  border-top: 2px solid ${theme.colors.text["text/75%"]};
  padding-top: 12px;
  margin-top: 18px;
`;

const ProductDisplay: React.FC = () => {
  const [quantity, setQuantity] = useState<number | undefined>();
  const { state: userState } = useContext(UserContext);
  const [costPerCredit, setCostPerCredit] = useState<number>(0);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/billing/get-credit-cost`)
      .then((res) => {
        setCostPerCredit((res.data || 0) / 100);
      })
      .catch();
  }, []);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(e.target.value));
  };
  return (
    <Section>
      <div className="product">
        <div>
          <h3>Buy Multiplayer credits</h3>
          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <h4>Cost: ${(costPerCredit * (quantity || 0)).toFixed(2)}</h4>
            <h4>Cost per credit: ${costPerCredit.toFixed(2)}</h4>
          </div>
        </div>
      </div>
      <form
        action={`${process.env.REACT_APP_API_URL}/billing/create-checkout-session`}
        method="POST"
      >
        <Input
          placeholder="Number of credits"
          type="number"
          min="5"
          name="quantity"
          required
          value={quantity}
          onChange={handleQuantityChange}
        />
        <input type="hidden" name="userId" value={userState?._id} />
        <Button type="submit">Checkout</Button>
      </form>
    </Section>
  );
};

const Message = ({ message }: any) => (
  <section>
    <p>{message}</p>
  </section>
);

export const Payment: React.FC = () => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready.",
      );
    }
  }, []);

  return message ? <Message message={message} /> : <ProductDisplay />;
};
