import styled from "@emotion/styled";
import React, { useContext } from "react";
import { CreditBalance } from "./Balance";
import { Payment } from "./payment";
import { LogoFullIcon } from "components/icons";
import { UserContext } from "context";

const Div = styled.div`
  max-width: 550px;
  margin: auto;
`;
export const ManageCredit: React.FC = () => {
  const { state: userState } = useContext(UserContext);
  return (
    <Div>
      <LogoFullIcon style={{ height: "58px", marginBottom: "0px" }} />
      <h1>Manage your Multiplayer credits</h1>
      <CreditBalance balance={userState?.creditBalance || 0} />
      <Payment />
    </Div>
  );
};
