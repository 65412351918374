import React from "react";
import { Text } from "components/common";
import { css } from "@emotion/css";

type Props = {
  balance: number;
};
export const CreditBalance: React.FC<Props> = ({ balance }) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        align-item: center;
        margin-bottom: 12px;
      `}
    >
      <Text.H4 style={{ marginRight: 10 }}>Current credit balance: </Text.H4>
      <Text.H4 color="text/green">{balance}</Text.H4>
    </div>
  );
};
