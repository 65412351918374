import { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Dialog, Text } from "components";
import { CustomGoogleIcon, LogoIcon, QuestionIcon } from "components/icons";
import { useQuery } from "@apollo/client";
import { css } from "@emotion/css";
import TextureBg from "./static/Texture.png";
import StartsBg from "./static/Stars.png";
import "./google.css";
import { getAccessToken, removeTokens } from "services";
import { E_Route } from "utils/constants";
import { GOOGLE_OAUTH_QUERY } from "apollo";
import { GoogleOauthQueryDto } from "types";
import { extractFromQuery } from "utils/helpers";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #181823;
  padding: 48px;
  background-image: url(${TextureBg});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
`;

const Starts = styled.div`
  background-image: url(${StartsBg});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-color: transparent;
  width: 100%;
  height: 100%;
`;

const LoginButton = styled.button`
  display: flex;
  flex-direction: row;
  max-width: 399px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(4)};
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(1)};
  border: 1px solid #312f48;
  background: #20202f;
  color: #aca7f6;
  font-size: 19px;
  cursor: pointer;

  @media (max-width: 1030px) {
    width: 80%;
    padding: ${({ theme }) => theme.spacing(2)}
      ${({ theme }) => theme.spacing(3)};
  }
`;

export const LoginPage = () => {
  const { data } = useQuery<any, GoogleOauthQueryDto>(GOOGLE_OAUTH_QUERY, {
    variables: {
      oauthData: {
        redirect: `https://ai.multiplayer.work${E_Route.AuthContinue}`,
        type: "login",
        scopes: [
          // "https://www.googleapis.com/auth/calendar.events",
          // "https://www.googleapis.com/auth/calendar.readonly",
          "https://www.googleapis.com/auth/userinfo.email",
          "https://www.googleapis.com/auth/userinfo.profile",
          "https://www.googleapis.com/auth/gmail.modify",
          "https://www.googleapis.com/auth/contacts.readonly",
          "https://www.googleapis.com/auth/contacts.other.readonly",
        ],
      },
    },
  });

  const checkAuth = (authWindow: Window | null) => {
    const intervalId = setInterval(() => {
      const token = getAccessToken();
      if (token) {
        clearInterval(intervalId);
        if (authWindow) {
          authWindow.close();
        }
        window.location.href = E_Route.Home;
      }
    }, 1000);
  };

  const handleGoogleLogin = useCallback(() => {
    if (data?.googleOAuthUrl) {
      removeTokens();
      const url = data?.googleOAuthUrl;
      const authWindow = window.open(url, "_blank");
      checkAuth(authWindow);
    }
  }, [data?.googleOAuthUrl]);

  useEffect(() => {
    const { source } = extractFromQuery<any>(window.location.search);
    if (source && source === "ads") {
      handleGoogleLogin();
    }
  }, [handleGoogleLogin]);

  return (
    <Container>
      <Starts>
        <div
          className={css`
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div>
            <LogoIcon style={{ width: "50px", height: "40px" }} />
          </div>

          <div
            className={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            `}
          >
            <Text.H1
              color="text/100%"
              marginBottom={0}
              marginTop={0}
              style={{
                fontSize: "36px",
                textAlign: "center",
                lineHeight: "48px",
              }}
            >
              Achieve Inbox Zero today
            </Text.H1>
            <Text.H4
              marginTop={4}
              marginBottom={0}
              style={{
                color: "#BDBDBD",
                fontSize: "17px",
                textAlign: "center",
                fontWeight: 300,
                marginBottom: "40px",
                lineHeight: "25px",
              }}
            >
              The first AI to manage your inbox for you
            </Text.H4>

            <LoginButton onClick={handleGoogleLogin}>
              <CustomGoogleIcon
                style={{
                  width: "27px",
                  height: "26px",
                  marginRight: "16px",
                }}
              />{" "}
              Continue with Google
            </LoginButton>

            {/* <DownloadButton /> */}

            {/* <div
                  style={{
                    margin: 'auto',
                    width: 'fit-content',
                  }}
                >
                  <GoogleSignInButton
                    disabled={!data?.googleOAuthUrl}
                    onClick={handleGoogleLogin}
                  />
                </div> */}
          </div>

          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <Dialog
              title="Why you’ll love Multiplayer"
              additionalContent={
                <div>
                  <p>
                    Multiplayer works like a human executive assistant, triaging
                    your existing Gmail inbox for you.
                  </p>
                  <h4>How it works:</h4>
                  <ul>
                    <li> Pre-reads your email for you</li>
                    <li>
                      Labels every email with one of the following:
                      <ul>
                        <li>@userfirstname</li>
                        <li>calendar</li>
                        <li>events</li>
                        <li>financial/receipts</li>
                        <li>notifications</li>
                        <li>newsletters</li>
                        <li>sales</li>
                        <li>travel</li>
                      </ul>
                    </li>
                    <li>
                      {" "}
                      Archives emails that don’t absolutely require your
                      attention
                    </li>
                    <li>
                      {" "}
                      Publishes a daily podcast summarizing less important
                      newsletters and other updates
                    </li>
                  </ul>
                </div>
              }
            >
              <p
                className={css`
                  color: #8d86f8;
                  text-align: center;
                  font-size: 19px;
                  font-style: normal;
                  font-weight: 500;
                  margin-top: 12px;
                  text-decoration: none;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  &:hover {
                    text-decoration: underline;
                  }
                `}
              >
                <QuestionIcon
                  fill="#8d86f8"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "6px",
                  }}
                />
                Learn more
              </p>
            </Dialog>
          </div>
        </div>
        <div
          className={css`
            width: 379.585px;
            height: 231.003px;
            transform: rotate(32.647deg);
            border-radius: 379.585px;
            opacity: 0.1;
            background: linear-gradient(
              180deg,
              rgba(140, 131, 195, 0) 0%,
              #6a53fe 100%
            );
            filter: blur(15.757162094116211px);
            position: absolute;
            bottom: -100px;
            left: -100px;
            z-index: 10;
          `}
        />
      </Starts>
    </Container>
  );
};

export default LoginPage;
